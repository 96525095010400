#header {
    width: 80%;
    margin: 0 auto;
    padding-top: .25em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.links {
    z-index: 15;
    list-style: none;
    display: flex;
    justify-content: space-between,
}

.links *:first-child {
    margin-right: 15px
}

.links * {
    border: 2px solid #FEFEFE;
    padding: .5em 1em;
    background-color: #FEFEFE;
    color: #222;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
}

.links *:hover {
    opacity: .9;
    box-shadow: 10px 4px 10px rgba(0, 0, 0, .4);
}

#header .main {
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile {
    border-radius: 50%;
    width: 70px;
    height: 70px
}
