.cardContainer {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.number-viewed {
    text-align: center;
}

.page-control {
    background-color: transparent;
    border-radius: 5px;
    padding: .5em 1em;
    margin: 0 1em
}

.page-control:hover {
    background-color: #222;
    font-size: 16px;
    color: #FEFEFE;
    border-radius: 5px;
    padding: .5em 1em;
    margin: 0 1em
}

@media only screen and (max-width: 1000px)  {
    .cardContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 550px)  {
    .cardContainer {
        display: flex;
        flex-direction: column;
    }
}