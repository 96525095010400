.card {
    display: flex; 
    flex-direction: column; 
    padding: 1em; 
    align-items: center; 
    margin: .5em 0;
    border-radius: 15px;
    background-color: rgba(33, 55, 66, 0.5);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    backdrop-filter: blur(20px);
    color: #FEFEFE;
    min-width: 300px;
    max-width: 400px;
    min-height: 300px;
    position: relative;
    z-index: 15;
    cursor: pointer;
}

.overlay {
    position: absolute;
    /* border-radius: 15px; */
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
    /* z-index: 10 */
}

.overlay-link {
    position: absolute;
    border-radius: 15px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .3);
}

.card .main {
    z-index: 11;
    position: absolute;
    bottom: 10%;
    right: 10%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

/* .card a {
    border: 2px solid #FEFEFE;
    padding: .5em 1em;
    color: #FEFEFE;
    border-radius: 5px;
    text-align: center;
} */

/* .card a:hover {
    background-color: #FEFEFE;
    color: #222;
} */

.opensea {
    z-index: 16;
    position: absolute;
    bottom: -1em;
    right: -1em
}

.opensea img {
    width: 50px;
    height: 50px;
}